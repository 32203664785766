@import "~@verified/veform/themes/default/style.scss";

/* cyrillic-ext */

body{
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	color: black;
}

.shadow-sm {
	-webkit-box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 50%) !important;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 50%) !important;
}
.card-body{
	padding: 0;
}
h1,h2,h3,h4,h5,h6{
	font-weight: bold;
}
.main {
	margin-top: 40px;
	max-width: 800px !important;
}
.btn-primary{
	background-color: #ec0000;
    border-color: #ec0000;
}
.btn-primary:hover{
	background-color: #c50f0f;
    border-color: #c50f0f;
}
.custom-checkbox-label{
	color: black !important;
}
.btn-plus {
	background: #808080;
	display: inline-block;
	border-radius: 50%;
	color: #fff;
	padding: 6px;
	font-size: 26px;
	line-height: 15px;
	&:hover {
		cursor: pointer;
		background: #808080;
	}
	margin: -5px 5px;
}

.btn-minus {
	background: #808080;;
	display: inline-block;
	border-radius: 50%;
	color: #fff;
	padding: 6px 9px;
	font-size: 26px;
	line-height: 15px;
	&:hover {
		cursor: pointer;
		background: #808080;
	}
	margin: -5px 5px;
}

.btn-minus-gray {
	background: #c1c1c1 !important;
	&:hover {
		cursor: not-allowed !important;
	}
}
.card{
	background: white;
}
.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	z-index: 1;
  }
  .smooth,
  .smooth * {
	transition: all 0.5s ease;
  
	&.delay,
	& .delay {
	  transition-delay: 0.5s;
	}
  }

  .form-control::placeholder {
	font-style: italic;
  }

ul.languages { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-direction: normal; -moz-box-direction: normal; -webkit-box-orient: horizontal; -moz-box-orient: horizontal; -webkit-flex-direction: row; -ms-flex-direction: row; flex-direction: row; -webkit-box-pack: flex-end; -moz-box-pack: flex-end; -webkit-justify-content: flex-end; -ms-flex-pack: flex-end; justify-content: flex-end; -webkit-box-pack: end; -moz-box-pack: end; -ms-flex-pack: end; -webkit-flex-wrap: nowrap; -moz-flex-wrap: nowrap; -ms-flex-wrap: nowrap; flex-wrap: nowrap; position: absolute; width: 100%; padding: 0; top: 0; max-width: 920px; margin: 0 0 0 -40px; list-style: none; background: #F3F3F3; }
ul.languages li a { display: inline-block; padding: 10px 7px; font-size: 0px; opacity: 0.8; }
ul.languages li a:hover { opacity: 1; }
ul.languages li a:hover .flag { -webkit-box-shadow: 0 0 8px rgba(66, 66, 66, 0.4); -moz-box-shadow: 0 0 8px rgba(66, 66, 66, 0.4); box-shadow: 0 0 8px rgba(66, 66, 66, 0.4); }
ul.languages li:last-child a { padding-right: 0; }

.flag { display: inline-block; width: 24px; height: 16px; background: url('/flags.png') no-repeat top left; }
.flag.da_DK { background-position: 0px -16px; }
.flag.sv_SE { background-position: 0px -32px; }
.flag.nb_NO { background-position: 0px -48px; }
.flag.fi_FI { background-position: 0px -64px; }
.flag.fr_FR { background-position: 0px -80px; }
.flag.de_DE { background-position: 0px -96px; }
.flag.nl_NL { background-position: 0px -112px; }
.flag.es_ES { background-position: 0px -128px; }
.flag.pl_PL { background-position: 0px -144px; }
	ul.languages {
		background: none;
	}

	.flag {
		border-radius: 4px;
	}
.file-select > .select-button {
    color: white;
    background-color: #808080;
    text-align: center;
    border-radius: calc(1.5em + 0.75rem + 2px);
    padding: 8px 20px !important;
    font-weight: 500;
    letter-spacing: 0.3px;
    font-size: 1rem;
    line-height: 1.5;
}

.file-select > input[type="file"] {
  display: none;
}
.steps ul li.current span {
    color: #EC0000;
	border: 1px solid #EC0000;
}

.steps ul li span {
    border-color: #F2F2F2;
    color: #F2F2F2;
}

// .required:after {
//     color: #808080;
// }
.steps ul li.complete span {
    background-color: #EC0000;
    border-color: #EC0000;
    color: #f2f2f2;
}
// .invalid-feedback,.custom-invalid-feedback,.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label{
// 	color: black !important;
// }

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #808080;
    border-color: #808080;
}

.tooltip-right:hover, .tooltip-left:hover {
    background: #EC0000;
    border: 1px solid #EC0000;
    color: #fff;
}
.tooltip-left {
    border: 1px solid #EC0000;
	color: #EC0000;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #EC0000;
    background-color: #EC0000;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    border-color: #EC0000;
    background-color: #EC0000;
}
.custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
    color: black !important;
}
.btn-warning {
    color: #fff;
    background-color: #EC0000;
    border-color: #EC0000;
}
.btn-warning:hover{
	color: #fff;
    background-color: #EC0000;
    border-color: #EC0000;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #EC0000;
    border-color: #EC0000;
}
.tip-label label{
	display: inline;
}

@media (max-width: 768px)
{
	.btn-steps{
		padding: 10px 0px;
	}
	.btn-left{
		text-align: center !important;
	}
}
b{
	font-weight:bold!important;
}
.headline{
	color:black;
}
 .invalid .multiselect__tags{
 border-color: #b62537 !important;
 }
 .valid .multiselect__tags {
 border-color: #218bcb !important;
 }
 .multiselect__tags{
	background-color: #fefefe!important;
    border-color: #c3c8ce!important;
	font-size: 12px!important;
	padding: 7px 40px 0 9px !important;
	min-height: 38px!important;
 }
 .multiselect__element{
	 font-size: 12px!important;
 }
 .multiselect__select:before{
	     border-color: #171616 transparent transparent!important;
		 border-width: 3px 4px 0!important;
		 top: 55%!important;
		 right: -6px!important;
 }
  .multiselect__select{
	  right: 0px!important;
  }
 .multiselect{
	 height: 38px!important;
	 min-height: 38px !important;
 }
 .multiselect__option--highlight{
	 background: #efefef!important;
	 color: #212529!important;
 }
 .multiselect__placeholder{
	 color: #212529!important;
	 margin-bottom: 8px !important;
 }
 .multiselect__option--highlight:after{
	 content: none!important;
 }
 .multiselect__option--selected:after{
	 content: none!important;
 }
 .multiselect__option--selected{
	 background-color: #007bff!important;
	 color:white!important;
 }
 .multiselect__input{
	 font-size: 12px!important;
 }
 .multiselect--active .multiselect__select{
	 transform: none!important; 
 }
 .customPadding{
 	padding : 0 10px;
 	width : 100% !important;
 }
 .overlayIfIE {
    position: absolute;
    top: 0;
    left:0;
    bottom: 0;

    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    z-index: 100;
    display:flex;
    align-items: center;
    justify-content: center;
}
.v-select-title{
	font-weight: 300;
    line-height: 1.5;
    color: #495057;
    font-size: 1rem !important;
}
.v-select-toggle{
	padding: 6px !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.remove-file {
	font-style: normal;
	font-size: 1rem;
	padding: 0 0.5rem;

	i:hover {
		transform: scale(1.5);
		color: darkred;
		cursor: pointer;
	}
}

.pdf-preview-container{
	padding-bottom: 15px;
}

.box{
	border-bottom: 1px dashed gray;
	padding-top: 20px;
}
.paddingtop15{
	padding-top: 15px;
}

.paddingleft25{
	padding-left: 25px;
}

.alert {
	position: relative;
	padding: 0.5rem 4rem;
	margin-top: .5rem;
	margin-left: .5rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	font-size:14px;
}

.file-error{
	color: #b62537;
	font-size: 14px;
	padding-top: 7px;
	// padding: 7px;
}

.padding5rem{
	padding: 5rem;
	font-size:18px;
}

@media screen and (max-width: 480px) {
	.padding5rem{
		padding: 1rem;
		font-size:16px;
	}
}