<template>
  <div id="cover">
    <div class="loader smooth" :style="{ opacity: loading ? 1 : 0 }">
      <img :src="'./loading.gif'" />
    </div>
    <div class="container" v-if="!loading && !sent">
      <div class="overlayIfIE" v-if="ud.isIE">
        <h3> {{ __("ifIE") }}</h3>
    </div>
      <ve-row>
        <ve-col>
          <!-- #region form1 -->
          <div class="card border-0 shadow-sm container" style="margin-top: 40px; margin-bottom: 40px;">
            <div class="card-body">
              <ve-row style="background-color: #f8f7f6;padding: 1.5rem;">
                <ve-col md5>
                  <img
                    :src="logo"
                    v-if="logo"
                    style="margin-right: 10px;padding-bottom:5px;width: 150px;"
                  />
                </ve-col>
                <ve-col md7>
                  <ve-headline
                    :headline="__('title')"
                    size="2em"
                    style="line-height: 90px;color:#F2F2F2"
                  ></ve-headline>
                </ve-col>
                <div class="md12" v-html="__('titleInfo')">
                </div>
              </ve-row>
              <ve-custom-stepper v-if="ud.isLegalForm&&isBisnodeFetched"
                :steps="steps()"
                :step="step"
                @step-click="previousStep"
                @btn-next-click="nextStep"
                @btn-back-click="backStep"
              ></ve-custom-stepper>
              <ve-custom-stepper v-if="!ud.isLegalForm&&isBisnodeFetched"
                :steps="steps()"
                :step="step"
                @step-click="previousStep"
                @btn-next-click="nextStep"
                @btn-back-click="backStep"
              ></ve-custom-stepper>
              <br />
              <br />
              <ve-form v-if="step == 1" ref="form1" class="ml-3">
                <div>
                  <h4>{{ __("sectionHead1") }}</h4>

                  <ve-row>
                    <ve-input-field
                      md12
                      xs12
                      :label="__('orgNo')"
                      type="text"
                      required
                      v-model="ud.orgNr"
                      :placeholder="__('orgNoPlaceholder')"
                      rules="required|min:11"
                      mask="######-####"
                      @input="fetchBisnode()"
                      :input-class="bisnodeError ? 'is-invalid' : ''"
                    />
                  <div class="alert alert-danger" role="alert" v-if="bisnodeError">
                    {{__('bisnodeError')}}
                  </div>
                  </ve-row>
                  <ve-row v-if="isBisnodeFetched">
                    <ve-input-field
                      md6
                      xs12
                      :label="__('companyName')"
                      type="text"
                      v-model="ud.companyName"
                      required
                      rules="required"
                    />
                    <ve-input-field
                      md6
                      xs12
                      :label="__('address')"
                      type="text"
                      v-model="ud.address"
                      required
                      rules="required"
                    />
                    <ve-input-field
                      md6
                      xs12
                      :label="__('zip')"
                      v-model="ud.zip"
                      required
                      rules="required"
                      mask="### ##"
                    />
                    <ve-input-field
                      md6
                      xs12
                      :label="__('city')"
                      v-model="ud.city"
                      required
                      rules="required"
                    />
                    
                  </ve-row>
                  <ve-row v-if="isBisnodeFetched">
                    <ve-input-field
                      v-if="
                        Object.entries(translations.yearlyTurnover).length !== 0
                      "
                      md6
                      xs12
                      :label="__('yearlyTurnover')"
                      type="text"
                      v-model="ud.yearlyTurnover"
                      required
                      rules="required"
                    />
                    <ve-input-field
                      v-if="
                        Object.entries(translations.noEmployees).length !== 0
                      "
                      md6
                      xs12
                      :label="__('noEmployees')"
                      type="text"
                      v-model="ud.noEmployees"
                      required
                      rules="required"
                    />
                    <ve-input-field
                      md12
                      xs12
                      :label="__('industry')"
                      type="text"
                      v-model="ud.industry"
                      required
                      rules="required"
                      :disabled="true"
                    />
                    <ve-col xs12 sm12>
                        <ve-form-group>
                          <ve-checkbox
                            v-model="ud.isOtherAddress"
                            inline
                            :options="{
                              yes: { label: __('isOtherAddress') }
                            }"
                            required
                          ></ve-checkbox>
                        </ve-form-group>
                      </ve-col>
                      <ve-input-field v-if="ud.isOtherAddress.yes"
                        md6
                        xs12
                        :label="__('address')"
                        type="text"
                        v-model="ud.address1"
                        required
                        rules="required"
                      />
                      <ve-input-field v-if="ud.isOtherAddress.yes"
                        md6
                        xs12
                        :label="__('zip')"
                        v-model="ud.zip1"
                        required
                        rules="required"
                        mask="### ##"
                      />
                      <ve-input-field v-if="ud.isOtherAddress.yes"
                        md6
                        xs12
                        :label="__('city')"
                        v-model="ud.city1"
                        required
                        rules="required"
                      />
                  </ve-row>
                  <br />
                  <h4 v-if="isBisnodeFetched">{{ __("sectionHead9") }}</h4>
                  <ve-row v-if="isBisnodeFetched">
                    <ve-input-field
                      md6
                      xs12
                      :label="__('firstName')"
                      type="text"
                      v-model="ud.contactFirstName"
                      required
                      rules="required"
                    />
                    <ve-input-field
                      md6
                      xs12
                      :label="__('lastName')"
                      type="text"
                      v-model="ud.contactLastName"
                      required
                      rules="required"
                    />
                    <ve-input-field
                      md6
                      xs12
                      :label="__('phone')"
                      type="number"
                      v-model="ud.contactPhone"
                      required
                      rules="required|number"
                    />
                    <ve-input-field
                      md6
                      xs12
                      :label="__('email')"
                      type="text"
                      v-model="ud.contactEmail"
                      required
                      rules="required|email"
                    />
                  </ve-row>
                </div>
              </ve-form>
              <ve-form v-if="step == 2" ref="form2" class="ml-3">
                <div class="md12">
                  {{ __("optionsInfo") }}
                </div>
                <ve-row>
                  <ve-col md12 xs12>
                    <ve-form-group>
                      <ve-checkbox v-if="ud.isLegalForm&&(ud.legalForm=='Aktiebolag'||ud.legalForm=='Handelsbolag'||ud.legalForm=='Kommanditbolag')"
                        v-model="ud.legalType"
                        :options="{
                          legalFormOp2: { label: __('legalFormOp2') },
                          legalFormOp8: { label: __('legalFormOp8') },
                          legalFormOp9: { label: __('legalFormOp9') }
                        }"
                        rules="required"
                        required
                        @input="setQuestions()"
                      />
                      <ve-checkbox v-if="ud.isLegalForm&&ud.legalForm=='Enskild firma'"
                        v-model="ud.legalType"
                        :options="{
                          legalFormOp3: { label: __('legalFormOp3') },
                          legalFormOp10: { label: __('legalFormOp10') }
                        }"
                        rules="required"
                        required
                        @input="setQuestions()"
                      />
                       <ve-checkbox v-if="!ud.isLegalForm"
                        v-model="ud.legalType"
                        :options="{
                          legalFormOp4: { label: __('legalFormOp4') },
                          legalFormOp5: { label: __('legalFormOp5') },
                          legalFormOp6: { label: __('legalFormOp6') },
                          legalFormOp7: { label: __('legalFormOp7') },
                          legalFormOp11: { label: __('legalFormOp11') },
                          legalFormOp12: { label: __('legalFormOp12') }
                        }"
                        rules="required"
                        required
                        @input="setQuestions()"
                      />
                    </ve-form-group>
                  </ve-col>
                </ve-row>
                <ve-row >
                    <!-- <h4>{{ __("legalFormOp1") }}</h4> -->
                   
                          <ve-col md12 xs12 sm12 v-if="isInQuestions('businessTurnOver')">
                              <ve-form-group>
                                <label required>{{ __('businessTurnOver') }}</label>
                                <ve-dropdown
                                  v-model="ud.businessTurnOver"
                                  rules="required"
                                  :items="ud.turnOverArray"
                                ></ve-dropdown>
                              </ve-form-group>
                            </ve-col>
                            <ve-col md12 xs12 sm12 v-if="isInQuestions('associationTurnOver')">
                              <ve-form-group>
                                <label required>{{ __('associationTurnOver') }}</label>
                                <ve-dropdown
                                  v-model="ud.associationTurnOver"
                                  rules="required"
                                  :items="ud.turnOverArray"
                                ></ve-dropdown>
                              </ve-form-group>
                            </ve-col>
                            <ve-col md12 xs12 sm12 v-if="isInQuestions('foundationTurnOver')">
                              <ve-form-group>
                                <label required>{{ __('foundationTurnOver') }}</label>
                                <ve-dropdown
                                  v-model="ud.foundationTurnOver"
                                  rules="required"
                                  :items="ud.turnOverArray"
                                ></ve-dropdown>
                              </ve-form-group>
                            </ve-col>
                        
                          <ve-col md12 xs12 sm12 v-if="isInQuestions('businessEmployees')">
                              <ve-form-group>
                                <label required>{{ __('businessEmployees') }}</label>
                                <ve-dropdown
                                  v-model="ud.businessEmployees"
                                  rules="required"
                                  :items="ud.employeesArray"
                                ></ve-dropdown>
                              </ve-form-group>
                            </ve-col>
                            <ve-col md12 xs12 sm12 v-if="isInQuestions('associationMembers')">
                              <ve-form-group>
                                <label required>{{ __('associationMembers') }}</label>
                                <ve-dropdown
                                  v-model="ud.associationMembers"
                                  rules="required"
                                  :items="ud.employeesArray"
                                ></ve-dropdown>
                              </ve-form-group>
                            </ve-col>
                             <ve-col md12 xs12 sm12 v-if="isInQuestions('foundationEmployees')">
                              <ve-form-group>
                                <label required>{{ __('foundationEmployees') }}</label>
                                <ve-dropdown
                                  v-model="ud.foundationEmployees"
                                  rules="required"
                                  :items="ud.employeesArray"
                                ></ve-dropdown>
                              </ve-form-group>
                            </ve-col>
                            <ve-input-field v-if="isInQuestions('businessFor')"
                              md12
                              xs12
                              :label="__('businessFor')"
                              type="text"
                              v-model="ud.businessFor"
                              required
                              rules="required"
                            />
                            <ve-input-field v-if="isInQuestions('foundationFor')"
                              md12
                              xs12
                              :label="__('foundationFor')"
                              type="text"
                              v-model="ud.foundationFor"
                              required
                              rules="required"
                            />
                            <ve-input-field v-if="isInQuestions('useOfIkea')"
                              md12
                              xs12
                              :label="__('useOfIkea')"
                              type="text"
                              v-model="ud.useOfIkea"
                              required
                              rules="required"
                            />
                            <ve-input-field v-if="isInQuestions('countOfUse')"
                              md12
                              xs12
                              :label="__('countOfUse')"
                              type="text"
                              v-model="ud.countOfUse"
                              required
                              rules="required"
                            />
                            <ve-input-field v-if="isInQuestions('associationActivities')"
                              md12
                              xs12
                              :label="__('associationActivities')"
                              type="text"
                              v-model="ud.associationActivities"
                              required
                              rules="required"
                            />
                           <!-- <ve-col md12 xs12>
                            <ve-input-field
                              md12
                              xs12
                              :label="__('form1Ques7')"
                              type="text"
                              v-model="ud.form1Ques7"
                              required
                              rules="required"
                            />
                          </ve-col> -->
                          <ve-col md12 xs12 sm12 v-if="isInQuestions('financialResource')">
                              <ve-form-group>
                                <label required>{{ __('financialResource') }}</label>
                                <ve-dropdown
                                  v-model="ud.financialResource"
                                  rules="required"
                                  :items="(ud.legalType.legalFormOp2 || ud.legalType.legalFormOp9)? ud.resourcesArray3: (ud.legalType.legalFormOp3? ud.resourcesArray2 : ((ud.legalType.legalFormOp11)? ud.resourcesArray : ud.resourcesArray1))"
                                ></ve-dropdown>
                              </ve-form-group>
                              <ve-input-field v-if="ud.financialResource=='Annan Källa'"
                                  type="text"
                                  v-model="ud.otherResource"
                                  :label="__('resourceOther')"
                                  required
                                  rules="required"
                                />
                                
                            </ve-col>
                            <ve-input-field v-if="ud.financialResource=='Ägartillskott'"
                                  md12
                                  xs12
                                  :label="__('foundationOwned')"
                                  type="text"
                                  v-model="ud.foundationOwned"
                                  required
                                  rules="required"
                                />
                            <ve-col md12 xs12 sm12 v-if="isInQuestions('companyFinancialResource')">
                              <ve-form-group>
                                <label required>{{ __('companyFinancialResource') }}</label>
                                <ve-dropdown
                                  v-model="ud.companyFinancialResource"
                                  rules="required"
                                  :items="(ud.legalType.legalFormOp8 || ud.legalType.legalFormOp12)? ud.resourcesArray: ud.resourcesArray2"
                                ></ve-dropdown>
                              </ve-form-group>
                              <ve-input-field v-if="ud.companyFinancialResource=='Annan Källa'"
                                  type="text"
                                  v-model="ud.companyOtherResource"
                                  :label="__('resourceOther')"
                                  required
                                  rules="required"
                                />
                                <ve-input-field v-if="ud.companyFinancialResource=='Ägartillskott'"
                                  md12
                                  xs12
                                  :label="__('foundationOwned')"
                                  type="text"
                                  v-model="ud.foundationOwned"
                                  required
                                  rules="required"
                                />
                            </ve-col>
                            <ve-col md12 xs12 sm12 v-if="isInQuestions('associationFinancialResource')">
                              <ve-form-group>
                                <label required>{{ __('associationFinancialResource') }}</label>
                                <ve-dropdown
                                  v-model="ud.associationFinancialResource"
                                  rules="required"
                                  :items="ud.resourcesArray1"
                                ></ve-dropdown>
                              </ve-form-group>
                              <ve-input-field v-if="ud.associationFinancialResource=='Annan Källa'"
                                  type="text"
                                  v-model="ud.associationOtherResource"
                                  :label="__('resourceOther')"
                                  required
                                  rules="required"
                                />
                            </ve-col>
                            <ve-col md12 xs12 sm12 v-if="isInQuestions('foundationFinancialResources')">
                              <label required>{{ __('foundationFinancialResources') }}</label>
                              <ve-input  md12 xs12 type="text" v-model="ud.foundationFinancialResources"
                                  required rules="required"
                              />
                                
                            </ve-col>
                          
                         
                              <ve-col md12 xs12 v-if="isInQuestions('businessInOtherCountry')">
                                <ve-form-group>
                                  <ve-label required class="tip-label">{{ __("businessInOtherCountry") }}</ve-label>
                                  <ve-radio 
                                    v-model="ud.businessInOtherCountry"
                                    :label="__('businessInOtherCountry')"
                                    :options="{
                                      yes: { label: __('yes') },
                                      no: { label: __('no') }
                                    }"
                                    :inline="true"
                                    rules="required"
                                    required
                                  />
                                </ve-form-group>
                                    <ve-form-group v-if="ud.businessInOtherCountry&&ud.businessInOtherCountry.yes">
                                     <ve-label :label="__('otherCountry')"  required/>
                                          <multiselect v-model="ud.otherCountry" :class="(!this.ud.otherCountry||(this.ud.otherCountry&&this.ud.otherCountry.length==0))?'invalid':'valid'"
                                            :multiple="true" :searchable="true" :options="countries" rules="required" placeholder="">
                                      </multiselect>
                                    </ve-form-group>
                              </ve-col>
                                <ve-col xs12 md12 v-if="isInQuestions('companyAddress')">
                                  <ve-form-group>
                                    <ve-label required>{{ __("companyAddress") }}</ve-label>
                                        <ve-textarea 
                                          md12
                                          xs12
                                          :label="__('companyAddress')"
                                          type="text"
                                          v-model="ud.companyAddress"
                                          required
                                          rules="required"
                                          rows="1"
                                        />
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 md12 v-if="isInQuestions('associationAddress')">
                                  <ve-form-group>
                                    <ve-label required>{{ __("associationAddress") }}</ve-label>
                                        <ve-textarea 
                                          md12
                                          xs12
                                          :label="__('associationAddress')"
                                          type="text"
                                          v-model="ud.associationAddress"
                                          required
                                          rules="required"
                                          rows="1"
                                        />
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 md12 v-if="isInQuestions('foundationAddress')">
                                  <ve-form-group>
                                    <ve-label required>{{ __("foundationAddress") }}</ve-label>
                                        <ve-textarea 
                                          md12
                                          xs12
                                          :label="__('foundationAddress')"
                                          type="text"
                                          v-model="ud.foundationAddress"
                                          required
                                          rules="required"
                                          rows="1"
                                        />
                                  </ve-form-group>
                                </ve-col>
                                <!-- <ve-input-field v-if="isInQuestions('associationAddress')"
                                  md12
                                  xs12
                                  :label="__('associationAddress')"
                                  type="text"
                                  v-model="ud.associationAddress"
                                  required
                                  rules="required"
                                />
                                <ve-input-field v-if="isInQuestions('foundationAddress')"
                                  md12
                                  xs12
                                  :label="__('foundationAddress')"
                                  type="text"
                                  v-model="ud.foundationAddress"
                                  required
                                  rules="required"
                                /> -->

                                <ve-input-field v-if="isInQuestions('whyChoose')"
                                  md12
                                  xs12
                                  :label="__('whyChoose')"
                                  type="text"
                                  v-model="ud.whyChoose"
                                  required
                                  rules="required"
                                />
                                <ve-input-field v-if="isInQuestions('invoiceCount')"
                                  md12
                                  xs12
                                  :label="__('invoiceCount')"
                                  type="text"
                                  v-model="ud.invoiceCount"
                                  required
                                  rules="required"
                                />
                                <ve-input-field v-if="isInQuestions('leasedItemUse')"
                                  md12
                                  xs12
                                  :label="__('leasedItemUse')"
                                  type="text"
                                  v-model="ud.leasedItemUse"
                                  required
                                  rules="required"
                                />
                                <ve-input-field v-if="isInQuestions('businessItem')"
                                  md12
                                  xs12
                                  :label="__('businessItem')"
                                  type="text"
                                  v-model="ud.businessItem"
                                  required
                                  rules="required"
                                />
                          <ve-col md12 xs12 v-if="isInQuestions('attachFile')&&ud.legalForm!='Handelsbolag'&&ud.legalForm!='Kommanditbolag'">
                            <ve-label :label="__('attachFile')" /><br>
                            <ve-row v-if="file">
                              <ve-col md2 xs6 class="atc-item" style="float:left;" >
                                  <transition name="slide-fade">
                                    <ve-pdf-viewer :src="file"
                                    :showPages = [1]>
                                    </ve-pdf-viewer>
                                  </transition>
                                  <a class="remove-button-atc remove-file" @click="removeFile('file','1')" style="font-weight:bold">
                                    <i class="fas fa-times"></i>
                                  </a>&nbsp;&nbsp;{{file.name}}
                              </ve-col>
                            </ve-row>
                            <br v-if="file">
                            <label class="file-select">
                              <div class="btn btn-primary next-btn">
                                <span>{{ __("uploadButton") }}</span>
                              </div>
                              <input
                                accept="application/pdf"
                                class="btn btn-primary next-btn"
                                type="file"
                                @change="handleFileChange"
                              />
                            </label>
                          </ve-col>
                          <ve-col md12 xs12 v-if="isInQuestions('attachMinutes')">
                            <ve-label :label="__('attachMinutes')" /><br>
                            <ve-row v-if="file1">
                              <ve-col md2 xs6 class="atc-item" style="float:left;" >
                                  <transition name="slide-fade">
                                    <ve-pdf-viewer :src="file1"
                                    :showPages = [1]>
                                    </ve-pdf-viewer>
                                  </transition>
                                  <a class="remove-button-atc remove-file" @click="removeFile('file','2')" style="font-weight:bold">
                                    <i class="fas fa-times"></i>
                                  </a>&nbsp;&nbsp;{{file1.name}}
                              </ve-col>
                            </ve-row>
                            <br v-if="file1">
                            <label class="file-select">
                              <div class="btn btn-primary next-btn">
                                <span>{{ __("uploadButton") }}</span>
                              </div>
                              <input
                                accept="application/pdf"
                                class="btn btn-primary next-btn"
                                type="file"
                                @change="handleFileChange1"
                              />
                            </label>
                            
                          </ve-col>
                          <ve-col md12 xs12 v-if="isInQuestions('attachCertificate')">
                            <ve-label :label="__('attachCertificate')" /><br>
                            <ve-row v-if="file2">
                              <ve-col md2 xs6 class="atc-item" style="float:left;" >
                                  <transition name="slide-fade">
                                    <ve-pdf-viewer :src="file2"
                                    :showPages = [1]>
                                    </ve-pdf-viewer>
                                  </transition>
                                  <a class="remove-button-atc remove-file" @click="removeFile('file','3')" style="font-weight:bold">
                                    <i class="fas fa-times"></i>
                                  </a>&nbsp;&nbsp;{{file2.name}}
                              </ve-col>
                            </ve-row>
                            <br v-if="file2">
                            <label class="file-select">
                              <div class="btn btn-primary next-btn">
                                <span>{{ __("uploadButton") }}</span>
                              </div>
                              <input
                                accept="application/pdf"
                                class="btn btn-primary next-btn"
                                type="file"
                                @change="handleFileChange2"
                              />
                            </label>
                          </ve-col>
                          <div class="file-error" style="padding:7px;" v-if="noFile&&ud.legalForm!='Handelsbolag'&&ud.legalForm!='Kommanditbolag'">
                           {{__('noFile')}}
                          </div>
                           
                </ve-row>
              </ve-form>

              <ve-form v-if="step == 3 && !completed" ref="form3" class="ml-3">
                <div v-if="ud.legalType&&(ud.legalType.legalFormOp2||ud.legalType.legalFormOp8||ud.legalType.legalFormOp9||ud.legalType.legalFormOp11||ud.legalType.legalFormOp12)">
                   <p v-if="ud.legalType.legalFormOp2||ud.legalType.legalFormOp8||ud.legalType.legalFormOp9" v-html="__('form1Info1') "></p>
                   <p v-if="ud.legalType.legalFormOp11||ud.legalType.legalFormOp12" v-html="__('form1Info16') "></p><br>

                    <ve-row>
                        <ve-col md12 xs12>
                          <ve-custom-radio  v-model="ud.alternative" nolabel
                            :options="{
                              alternative1: { label: __('alternative1') }
                            }" :inline="true" rules="required"/>
                        </ve-col>
                        <p v-html="__('form1Info2') " class="col col-12 col-md-12 paddingleft25" ></p><br>
                        <p v-html="__('form1Info3') " class="col col-12 col-md-12 paddingleft25" v-if="ud.alternative.alternative1"></p>

                        <ve-row v-if="ud.alternative.alternative1" class="paddingleft25">
                          <div v-for="(person, i) in ud.persons" :key="i" class="customPadding box">
                                <div class="row">
                                  <ve-col md6 xs12 sm12>
                                    <ve-form-group>
                                      <label required>{{ __('nationality') }}</label>
                                      <ve-select
                                        :searchable="false"
                                        v-model="ud.persons[i].nationality"
                                        :options="ud.nationalityArray"
                                        @on-select="ud.persons[i].nationality=='Svensk'?ud.persons[i].noPassportFile=false:ud.persons[i].noPassportFile=true"
                                        :readonly="true" rules="required"/>

                                    </ve-form-group>
                                  </ve-col>

                                  <ve-col xs12 sm12 md6 v-if="ud.persons[i].nationality=='Annan'">
                                      <ve-form-group>
                                        <ve-label required>{{ __("otherNationality") }}</ve-label>
                                        <ve-input v-model="ud.persons[i].place" rules="required" blurvalidate ></ve-input>
                                      </ve-form-group>
                                  </ve-col>

                                  <ve-col xs12 sm6>
                                    <ve-form-group>
                                      <ve-label required v-if="ud.persons[i].nationality && ud.persons[i].nationality=='Svensk'">{{ __("ssn") }}</ve-label>
                                      <ve-label required v-if="ud.persons[i].nationality && ud.persons[i].nationality!='Svensk'">{{ __("dob") }}</ve-label>
                                      <ve-input v-if="ud.persons[i].nationality && ud.persons[i].nationality!='Svensk'"
                                        v-model="ud.persons[i].ssn"
                                        mask="####-##-##"
                                        placeholder="ÅÅÅÅ-MM-DD"
                                        rules="required|dob"></ve-input>
                                      <ve-input v-if="ud.persons[i].nationality&&ud.persons[i].nationality=='Svensk'"
                                        @input="fetchSignatoryBisnode(i)"
                                        v-model="ud.persons[i].ssn"
                                        rules="required|min:13"
                                        mask="########-####"
                                        placeholder="ÅÅÅÅMMDD-XXXX"></ve-input>
                                    </ve-form-group>
                                  </ve-col>
                                  <ve-col xs12 sm6>
                                    <ve-form-group>
                                      <ve-label required>{{ __("firstName") }}</ve-label>
                                      <ve-input v-model="ud.persons[i].firstName" rules="required"></ve-input>
                                    </ve-form-group>
                                  </ve-col>

                                  <ve-col xs12 sm6>
                                    <ve-form-group>
                                      <ve-label required>{{ __("lastName") }}</ve-label>
                                      <ve-input v-model="ud.persons[i].lastName" rules="required"></ve-input>
                                    </ve-form-group>
                                  </ve-col>

                                  <ve-col xs12 sm6>
                                    <ve-form-group>
                                      <ve-label required>{{ __("address") }}</ve-label>
                                      <ve-input v-model="ud.persons[i].address" rules="required" ></ve-input>
                                    </ve-form-group>
                                  </ve-col>
                                  <ve-col xs12 sm6 v-if="ud.persons[i].nationality&&ud.persons[i].nationality!='Svensk'">
                                    <ve-form-group>
                                      <ve-label required>{{ __("zip") }}</ve-label>
                                      <ve-input v-model="ud.persons[i].zip" rules="required"></ve-input>
                                    </ve-form-group>
                                  </ve-col>
                                  <ve-col xs12 sm6 v-if="ud.persons[i].nationality&&ud.persons[i].nationality=='Svensk'">
                                    <ve-form-group>
                                      <ve-label required>{{ __("zip") }}</ve-label>
                                      <ve-input v-model="ud.persons[i].zip" rules="required|zipse" mask="### ##"></ve-input>
                                    </ve-form-group>
                                  </ve-col>
                                  
                                  <ve-col xs12 sm6>
                                    <ve-form-group>
                                      <ve-label required>{{ __("city") }}</ve-label>
                                      <ve-input v-model="ud.persons[i].city" rules="required"></ve-input>
                                    </ve-form-group>
                                  </ve-col>


                                  <!-- <ve-col xs12 sm6>
                                    <ve-form-group>
                                      <ve-label required>{{ __("firstName") }}</ve-label>
                                      <ve-input
                                        v-model="ud.persons[i].firstName"
                                      :rules="required"
                                      :disabled="ud.form1Info5.yes==true"
                                      ></ve-input>
                                    </ve-form-group>
                                  </ve-col>

                                  <ve-col xs12 sm6>
                                    <ve-form-group>
                                      <ve-label required>{{ __("lastName") }}</ve-label>
                                      <ve-input
                                        v-model="ud.persons[i].lastName"
                                        rules="required"
                                        :disabled="ud.form1Info5.yes==true"
                                      ></ve-input>
                                    </ve-form-group>
                                  </ve-col>
                                  
                                  
                                  <ve-col xs12 sm6>
                                    <ve-form-group>
                                      <ve-label required v-if="ud.persons[i].nationality&&ud.persons[i].nationality!='Svensk'">{{ __("dob") }}</ve-label>
                                      <ve-label required v-else>{{ __("ssn") }}</ve-label>
                                      <datepicker v-model="ud.persons[i].ssn" v-if="ud.persons[i].nationality&&ud.persons[i].nationality!='Svensk'"
                                                    name="datepicker_dob"
                                                    id="datepicker-dob"
                                                    required
                                                    format="yyyy.MM.dd"
                                                    :language="sv"
                                                    :monday-first="true"
                                                    :input-class="'calendar-icon form-control'"
                                                    :disabled="ud.form1Info5.yes==true"
                                                  ></datepicker>
                                      <ve-input v-else
                                        v-model="ud.persons[i].ssn"
                                        :rules="ud.form1Info4.yes==true?'required|min:13':''"
                                        mask="########-####"
                                        placeholder="ÅÅÅÅMMDD-XXXX"
                                        :disabled="ud.form1Info5.yes==true"
                                      ></ve-input>
                                    </ve-form-group>
                                  </ve-col>
                                  <ve-col xs12 sm6>
                                    <ve-form-group>
                                      <ve-label required>{{ __("address") }}</ve-label>
                                      <ve-input
                                        v-model="ud.persons[i].address"
                                        :rules="ud.form1Info4.yes==true?'required':''"
                                        :disabled="ud.form1Info5.yes==true"
                                      ></ve-input>
                                    </ve-form-group>
                                  </ve-col>
                                  <ve-col xs12 sm6>
                                    <ve-form-group>
                                      <ve-label required>{{ __("zip") }}</ve-label>
                                      <ve-input
                                        v-model="ud.persons[i].zip"
                                        :rules="ud.form1Info4.yes==true?'required':''"
                                        :disabled="ud.form1Info5.yes==true"
                                      ></ve-input>
                                    </ve-form-group>
                                  </ve-col>
                                  <ve-col xs12 sm6>
                                    <ve-form-group>
                                      <ve-label required>{{ __("city") }}</ve-label>
                                      <ve-input
                                        v-model="ud.persons[i].city"
                                      :rules="ud.form1Info4.yes==true?'required':''"
                                      :disabled="ud.form1Info5.yes==true"
                                      ></ve-input>
                                    </ve-form-group>
                                  </ve-col> -->



                                  <ve-col md12 xs12 v-if="isInQuestions('mainIncome')">
                                    <ve-form-group>
                                      <ve-label required class="tip-label" v-if="ud.isLegalForm&&ud.legalForm=='Enskild firma'">{{ __("mainIncome1") }}</ve-label>
                                      <ve-label required class="tip-label" v-else>{{ __("mainIncome") }}</ve-label>
                                      <ve-radio 
                                        v-model="ud.persons[i].mainIncome"
                                        :options="{
                                          incomeOp1: { label: __('incomeOp1') },
                                          incomeOp2: { label: __('incomeOp2') },
                                          incomeOp3: { label: __('incomeOp3') },
                                          incomeOp4: { label: __('incomeOp4') }
                                        }"
                                        rules="required"
                                        required
                                      />
                                    </ve-form-group>
                                    <ve-input-field v-if="ud.persons[i].mainIncome&&ud.persons[i].mainIncome.incomeOp4"
                                        md12
                                        xs12
                                        type="text"
                                        v-model="ud.persons[i].otherSource"
                                        :label="__('otherSource')"
                                        required
                                        rules="required"
                                      />
                                  </ve-col>
                                  <ve-col xs12 sm12 v-if="ud.persons[i].nationality&&ud.persons[i].nationality!='Svensk'">
                                      <ve-label >{{ __("otherCountryInfo") }}</ve-label>
                                  </ve-col>
                                  <br>
                                  <ve-col md12 xs12 v-if="ud.persons[i].nationality && ud.persons[i].nationality!='Svensk'">
                                    <ve-row v-if="passportFiles.length>0 && passportFiles[i]">
                                      <ve-col md2 xs6 class="atc-item" style="float:left;" >
                                          <transition name="slide-fade">
                                            <ve-pdf-viewer :src="passportFiles[i]"
                                            :showPages = [1]>
                                            </ve-pdf-viewer>
                                          </transition>
                                          <a class="remove-button-atc remove-file" @click="removeFile('passports',i)" style="font-weight:bold">
                                            <i class="fas fa-times"></i>
                                          </a>&nbsp;&nbsp;{{passportFiles[i].name}}
                                      </ve-col>
                                    </ve-row>
                                    <br>
                                    <label class="file-select">
                                      <div class="btn btn-primary next-btn">
                                        <span>{{ __("uploadButton") }}</span>
                                      </div>
                                      <input
                                        accept="application/pdf"
                                        class="btn btn-primary next-btn"
                                        type="file"
                                        @change="handleFileChange6($event,i,'persons')"
                                      />
                                    </label>
                                    <div class="file-error" v-if="!ud.persons[i].hasOwnProperty('noPassportFile') || ud.persons[i].noPassportFile">
                                    {{__('noFile')}}
                                    </div>
                                  </ve-col>
                                </div>
                                <br>
                          </div>
                          
                          <br>
                          <ve-col md2 xs12 v-if="ud.persons.length > 1" class="paddingtop15">
                            <button
                              class="btn btn-warning te"
                              type="button"
                              @click="ud.persons.pop()"
                            >
                              {{ __("removeOwner") }}
                            </button>
                          </ve-col>
                          <ve-col md3 v-if="ud.persons.length < 3" class="paddingtop15">
                            <button
                              class="btn btn-primary te"
                              type="button"
                              @click="addPersons()"
                            >
                              {{ __("addOwner") }}
                            </button>
                          </ve-col>
                          <br>
                      </ve-row>
                      <br>
                    </ve-row>
                   
                   

                   <ve-row v-if="ud.alternative.alternative1" class="paddingleft25">
                      <ve-col xs12 sm12>
                        <ve-form-group>
                          <ve-checkbox
                            v-model="ud.form1Info4"
                            inline
                            :options="{
                              yes: { label: __('form1Info4') }
                            }"
                            required
                          ></ve-checkbox>
                        </ve-form-group>
                      </ve-col>
                      <ve-col md12 xs12 v-if="ud.form1Info4.yes">
                            <ve-row v-if="file3">
                              <ve-col md2 xs6 class="atc-item" style="float:left;" >
                                  <transition name="slide-fade">
                                    <ve-pdf-viewer :src="file3"
                                    :showPages = [1]>
                                    </ve-pdf-viewer>
                                  </transition>
                                  <a class="remove-button-atc remove-file" @click="removeFile('file','4')" style="font-weight:bold">
                                    <i class="fas fa-times"></i>
                                  </a>&nbsp;&nbsp;{{file3.name}}
                              </ve-col>
                            </ve-row>
                            <br>
                            <label class="file-select">
                              <div class="btn btn-primary next-btn">
                                <span>{{ __("uploadButton") }}</span>
                              </div>
                              <input
                                accept="application/pdf"
                                class="btn btn-primary next-btn"
                                type="file"
                                @change="handleFileChange3"
                              />
                            </label>
                            <div class="file-error" v-if="noFile1">
                              {{__('noFile')}}
                            </div>
                        </ve-col>
                    </ve-row>

                   <hr>
                   <ve-custom-radio  v-model="ud.alternative" nolabel
                    :options="{
                      alternative2: { label: __('alternative2') }
                    }" :inline="true" rules="required"/>

                    <p v-html="__('alternative2Info') " class="col col-12 col-md-12 paddingleft25" ></p><br>
                     
                </div>
                <div v-if="ud.legalType&&(ud.legalType.legalFormOp3||ud.legalType.legalFormOp10)">
                  <h4>{{ __("sectionHead7") }}</h4>
                  <ve-row>
                    <ve-col xs12 sm12>
                      <ve-form-group>
                        <ve-custom-checkbox
                          v-model="ud.agree"
                          rules="required"
                          inline
                          :options="{
                            agree: { label: __('agree') }
                          }"
                          :data="{ validation_error: 'Test' }"
                        ></ve-custom-checkbox>
                      </ve-form-group>
                    </ve-col>
                    <ve-col xs12 sm12>
                      <ve-form-group>
                        <ve-custom-checkbox
                          v-model="ud.agree1"
                          rules="required"
                          inline
                          :options="{
                            agree1: { label: __('agree1') }
                          }"
                          :data="{ validation_error: 'Test' }"
                        ></ve-custom-checkbox>
                      </ve-form-group>
                    </ve-col>
                  </ve-row>

                  <h4>{{ __("signDocument") }}</h4>
                  <p v-html="__('signInfo') "></p>
                  <br>
                  <h4>{{ __("firmaTeckning1") }}</h4>
                  <p v-for="val in signature">{{val}}</p>
                  <p v-html="__('firmaTeckning2') "></p><br>
                  <p v-html="__('firmaTeckning3') " v-if="ud.legalForm != 'Enskild firma'"></p>
                    
                      <ve-row>
                        <h4>{{ __("sectionHead8") }}</h4>
                        <ve-col md12 xs12>
                          <ve-form-group>
                            <ve-radio
                              v-model="ud.forward"
                              :label="__('forward')"
                              :options="{
                                signDirect: { label: __('signDirect') },
                                signForward: { label: __('signForward') }
                              }"
                              :inline="false"
                              @radio-click="updateBoardMembers"
                              rules="required"
                            />
                          </ve-form-group>
                        </ve-col>
                      </ve-row>
                      <ve-row v-if="(ud.forward.signForward || ud.forward.signDirect) && !isEmpty()">
                        <ve-label :label="__('chooseNameFromList')" />
                        <ve-col md12 xs12>
                          <ve-select
                            :options="ud.boardMembersList"
                            :searchable="true"
                            v-model="ud.boardMembers"
                            rules="required"
                            required
                            @on-select="updateOtherBoardMembers"
                          />
                        </ve-col>
                        <ve-col md6 xs12 v-if="ud.boardMembers && ud.boardMembers !='Jag är inte med i listan men har rätt att signera (fullmakt)'" >
                          <ve-input-field
                            :label="__('my_email')"
                            type="email"
                            v-model="ud.my_email"
                            rules="required|email"
                            required
                          />
                        </ve-col>
                        <ve-col md6 xs12  v-if="ud.boardMembers && ud.boardMembers !='Jag är inte med i listan men har rätt att signera (fullmakt)'">
                        <ve-form-group>
                          <ve-label :label="__('signingMethod')" required />
                          <ve-select
                            :options="methods"
                            v-model="ud.signingMethod"
                            rules="required"
                          />
                        </ve-form-group>
                      </ve-col>
                        <ve-col md4 xs12 v-if="ud.boardMembers =='Jag är inte med i listan men har rätt att signera (fullmakt)'">
                          <ve-input-field
                            :label="__('nolist_firstName')"
                            type="text"
                            v-model="ud.nolist_firstName"
                            required
                            rules="required"
                          />
                        </ve-col>
                        <ve-col md4 xs12 v-if="ud.boardMembers == 'Jag är inte med i listan men har rätt att signera (fullmakt)'">
                          <ve-input-field
                            :label="__('nolist_lastName')"
                            type="text"
                            v-model="ud.nolist_lastName"
                            required
                            rules="required"
                          />
                        </ve-col>
                        <ve-col md4 xs12 v-if="ud.boardMembers =='Jag är inte med i listan men har rätt att signera (fullmakt)'">
                          <ve-input-field
                            :label="__('nolist_email')"
                            type="email"
                            v-model="ud.nolist_email"
                            required
                            rules="required|email"
                          />
                        </ve-col>
                        <ve-col md6 xs12  v-if="ud.boardMembers =='Jag är inte med i listan men har rätt att signera (fullmakt)'">
                          <ve-form-group>
                            <ve-label :label="__('signingMethod')" required />
                            <ve-select
                              :options="methods"
                              v-model="ud.signingMethod"
                              rules="required"
                            />
                          </ve-form-group>
                        </ve-col>
                      </ve-row>

                      <ve-row v-if="(ud.forward.signForward || ud.forward.signDirect) && isEmpty()">
                      <ve-col md12 xs12>
                        <ve-label
                          xs12
                          :label="__('fillSignEmail')"
                          v-if="ud.forward.signDirect"
                        />
                        <ve-label
                          xs12
                          :label="__('fillSignForwardEmail')"
                          v-if="ud.forward.signForward"
                        />
                      </ve-col>
                        <ve-col md6 xs12>
                          <ve-input-field
                            :label="__('nolist_firstName')"
                            type="text"
                            v-model="ud.nolist_firstName"
                            required
                            rules="required"
                          />
                        </ve-col>
                        <ve-col md6 xs12>
                          <ve-input-field
                            md12
                            xs12
                            :label="__('nolist_lastName')"
                            type="text"
                            v-model="ud.nolist_lastName"
                            required
                            rules="required"
                          />
                        </ve-col>
                        <ve-col md6 xs12>
                          <ve-input-field
                            md12
                            xs12
                            :label="__('nolist_email')"
                            type="email"
                            v-model="ud.nolist_email"
                            required
                            rules="required|email"
                          />
                        </ve-col>
                        <ve-col md6 xs12 >
                          <ve-form-group>
                            <ve-label :label="__('signingMethod')" required />
                            <ve-select
                              :options="methods"
                              v-model="ud.signingMethod"
                              rules="required"
                            />
                          </ve-form-group>
                        </ve-col>
                      </ve-row>

                      <ve-row v-if="(ud.forward.signForward || ud.forward.signDirect) && isEmpty() && ud.legalForm != 'Enskild firma'">
                        <ve-col xs12 sm12>
                          <ve-form-group>
                            <ve-checkbox
                              v-model="ud.noListAddSigner"
                              inline
                              :options="{
                                sign1: { label: __('chooseSecondNameFromList') }
                              }"
                            ></ve-checkbox>
                          </ve-form-group>
                        </ve-col>
                      </ve-row>
                      <ve-row v-if="ud.noListAddSigner && ud.noListAddSigner.sign1 == true">
                        <ve-col md6 xs12>
                          <ve-input-field
                            :label="__('secondlist_firstName')"
                            type="text"
                            v-model="ud.secondlist_firstName"
                            required
                            rules="required"
                          />
                        </ve-col>
                        <ve-col md6 xs12>
                          <ve-input-field
                            md12
                            xs12
                            :label="__('secondlist_lastName')"
                            type="text"
                            v-model="ud.secondlist_lastName"
                            required
                            rules="required"
                          />
                        </ve-col>
                        <ve-col md6 xs12>
                          <ve-input-field
                            md12
                            xs12
                            :label="__('secondlist_email')"
                            type="email"
                            v-model="ud.secondlist_email"
                            required
                            rules="required|email"
                          />
                        </ve-col>
                        <ve-col md6 xs12 >
                          <ve-form-group>
                            <ve-label :label="__('signingMethod')" required />
                            <ve-select
                              :options="methods"
                              v-model="ud.signingMethod2"
                              rules="required"
                            />
                          </ve-form-group>
                        </ve-col>
                      </ve-row>

                      <ve-row v-if="(ud.forward.signForward || ud.forward.signDirect) && ud.boardMembers && ud.legalForm != 'Enskild firma'">
                        <ve-col xs12 sm12>
                          <ve-form-group>
                            <ve-checkbox
                              v-model="ud.org_signing"
                              inline
                              :options="{
                                sign: { label: __('org_signing') }
                              }"
                            ></ve-checkbox>
                          </ve-form-group>
                        </ve-col>
                      </ve-row>
                      <ve-row v-if="ud.org_signing && ud.org_signing.sign">
                        <ve-label :label="__('choosefromBoardMemberList')" required/>
                        <ve-col md12 xs12>
                          <ve-select
                            :options="ud.otherBoardMembersList"
                            :searchable="true"
                            v-model="ud.otherBoardMembers"
                            rules="required"
                          />
                        </ve-col>
                        <ve-col md6 xs12 v-if="ud.otherBoardMembers">
                          <ve-input-field
                            :label="__('other_email')"
                            type="email"
                            v-model="ud.other_email"
                            required
                            rules="required|email"
                          />
                        </ve-col>
                        <ve-col md6 xs12 >
                          <ve-form-group>
                            <ve-label :label="__('signingMethod')" required />
                            <ve-select
                              :options="methods"
                              v-model="ud.signingMethod2"
                              rules="required"
                            />
                          </ve-form-group>
                        </ve-col>
                      </ve-row>
                </div>
                <div v-if="ud.legalType&&(ud.legalType.legalFormOp4||ud.legalType.legalFormOp7)">
                   <p v-html="__('form4Info1') "></p><br>
                   <p v-html="__('form4Info2') "></p>
                   <br>
                    <ve-row>
                      <ve-col xs12 sm12>
                        <ve-form-group>
                          <ve-label>{{__('form4Info4')}}</ve-label>
                          <ve-radio
                            v-model="ud.form4Info4"
                            inline
                            rules="required"
                            :options="{
                              no: { label: __('form4Info5') },
                              yes: { label: __('form4Info6') }
                            }"
                          ></ve-radio>
                        </ve-form-group>
                      </ve-col>
                    </ve-row>
                    <ve-row v-if="ud.form4Info4.no">
                      <ve-col xs12 sm12>
                        <ve-form-group>
                          <ve-label>{{__('form4Info8')}}</ve-label>
                          <ve-checkbox
                            v-model="ud.form4Info3"
                            inline
                            :options="{
                              yes: { label: __('form4Info3') }
                            }"
                          ></ve-checkbox>
                        </ve-form-group>
                      </ve-col>

                      <ve-col md12 xs12 v-if="ud.form4Info3.yes">
                          <ve-row v-if="file6">
                            <ve-col md2 xs6 class="atc-item" style="float:left;" >
                                <transition name="slide-fade">
                                  <ve-pdf-viewer :src="file6"
                                  :showPages = [1]>
                                  </ve-pdf-viewer>
                                </transition>
                                <a class="remove-button-atc remove-file" @click="removeFile('file','7')" style="font-weight:bold">
                                  <i class="fas fa-times"></i>
                                </a>&nbsp;&nbsp;{{file6.name}}
                            </ve-col>
                          </ve-row>
                          <br>
                          <label class="file-select">
                            <div class="btn btn-primary next-btn">
                              <span>{{ __("uploadButton") }}</span>
                            </div>
                            <input
                              accept="application/pdf"
                              class="btn btn-primary next-btn"
                              type="file"
                              @change="handleFileChange7"
                            />
                          </label>
                          <div class="file-error" v-if="noFile3">
                            {{__('noFile')}}
                          </div>
                      </ve-col>
                      
                    </ve-row>
                    <ve-row v-if="!ud.form4Info3.yes">
                        <div v-for="(person, i) in ud.persons" :key="i" class="customPadding box">
                              <div class="row">
                                <!-- <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("firstName") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons[i].firstName"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>

                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("lastName") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons[i].lastName"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col md6 xs12 sm12>
                                  <ve-form-group>
                                    <label required>{{ __('nationality') }}</label>
                                    <ve-dropdown
                                      v-model="ud.persons[i].nationality"
                                      rules="required"
                                      :items="ud.nationalityArray"
                                    ></ve-dropdown>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm12 md6 v-if="ud.persons[i].nationality=='Annan'">
                                  <ve-form-group>
                                    <ve-label required>{{ __("otherNationality") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons[i].place"
                                      
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required v-if="ud.persons[i].nationality&&ud.persons[i].nationality!='Svensk'">{{ __("dob") }}</ve-label>
                                    <ve-label required v-else>{{ __("ssn") }}</ve-label>
                                    <datepicker v-model="ud.persons[i].ssn" v-if="ud.persons[i].nationality&&ud.persons[i].nationality!='Svensk'"
                                                  name="datepicker_dob"
                                                  id="datepicker-dob"
                                                  required
                                                  format="yyyy.MM.dd"
                                                  :language="sv"
                                                  :monday-first="true"
                                                   :input-class="'calendar-icon form-control'"
                                                ></datepicker>
                                    <ve-input v-else
                                      v-model="ud.persons[i].ssn"
                                      mask="########-####"
                                      placeholder="ÅÅÅÅMMDD-XXXX"
                                    ></ve-input>
                                    
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("address") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons[i].address"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("zip") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons[i].zip"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("city") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons[i].city"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col> -->

                                <ve-col md6 xs12 sm12>
                                  <ve-form-group>
                                    <label required>{{ __('nationality') }}</label>
                                    <ve-select
                                      :searchable="false"
                                      v-model="ud.persons[i].nationality"
                                      :options="ud.nationalityArray"
                                       @on-select="ud.persons[i].nationality=='Svensk'?ud.persons[i].noPassportFile=false:ud.persons[i].noPassportFile=true"
                                      :readonly="true" rules="required"/>

                                  </ve-form-group>
                                </ve-col>

                                <ve-col xs12 sm12 md6 v-if="ud.persons[i].nationality=='Annan'">
                                    <ve-form-group>
                                      <ve-label required>{{ __("otherNationality") }}</ve-label>
                                      <ve-input v-model="ud.persons[i].place" rules="required" blurvalidate ></ve-input>
                                    </ve-form-group>
                                </ve-col>

                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required v-if="ud.persons[i].nationality && ud.persons[i].nationality=='Svensk'">{{ __("ssn") }}</ve-label>
                                    <ve-label required v-if="ud.persons[i].nationality && ud.persons[i].nationality!='Svensk'">{{ __("dob") }}</ve-label>
                                    <ve-input v-if="ud.persons[i].nationality && ud.persons[i].nationality!='Svensk'"
                                      v-model="ud.persons[i].ssn"
                                      mask="####-##-##"
                                      placeholder="ÅÅÅÅ-MM-DD"
                                      rules="required|dob"></ve-input>
                                    <ve-input v-if="ud.persons[i].nationality && ud.persons[i].nationality=='Svensk'"
                                      v-model="ud.persons[i].ssn"
                                      @input="fetchSignatoryBisnode(i)"
                                      rules="required|min:13"
                                      mask="########-####"
                                      placeholder="ÅÅÅÅMMDD-XXXX"></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("firstName") }}</ve-label>
                                    <ve-input v-model="ud.persons[i].firstName" rules="required"></ve-input>
                                  </ve-form-group>
                                </ve-col>

                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("lastName") }}</ve-label>
                                    <ve-input v-model="ud.persons[i].lastName" rules="required"></ve-input>
                                  </ve-form-group>
                                </ve-col>

                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("address") }}</ve-label>
                                    <ve-input v-model="ud.persons[i].address" rules="required" ></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6 v-if="ud.persons[i].nationality&&ud.persons[i].nationality!='Svensk'">
                                  <ve-form-group>
                                    <ve-label required>{{ __("zip") }}</ve-label>
                                    <ve-input v-model="ud.persons[i].zip" rules="required"></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6 v-if="ud.persons[i].nationality&&ud.persons[i].nationality=='Svensk'">
                                  <ve-form-group>
                                    <ve-label required>{{ __("zip") }}</ve-label>
                                    <ve-input v-model="ud.persons[i].zip" rules="required|zipse" mask="### ##"></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("city") }}</ve-label>
                                    <ve-input v-model="ud.persons[i].city" rules="required"></ve-input>
                                  </ve-form-group>
                                </ve-col>


                                <ve-col md12 xs12 v-if="isInQuestions('mainIncome') && ud.form4Info4.yes">
                                  <ve-form-group>
                                    <ve-label required class="tip-label" v-if="ud.isLegalForm&&ud.legalForm=='Enskild firma'">{{ __("mainIncome1") }}</ve-label>
                                    <ve-label required class="tip-label" v-else>{{ __("mainIncome") }}</ve-label>
                                    <ve-radio 
                                      v-model="ud.persons[i].mainIncome"
                                      :options="{
                                        incomeOp1: { label: __('incomeOp1') },
                                        incomeOp2: { label: __('incomeOp2') },
                                        incomeOp3: { label: __('incomeOp3') },
                                        incomeOp4: { label: __('incomeOp4') }
                                      }"
                                      rules="required"
                                      required
                                    />
                                  </ve-form-group>
                                  <ve-input-field v-if="ud.persons[i].mainIncome&&ud.persons[i].mainIncome.incomeOp4"
                                      md12
                                      xs12
                                      type="text"
                                      v-model="ud.persons[i].otherSource"
                                      :label="__('otherSource')"
                                      required
                                      rules="required"
                                    />
                                </ve-col>
                                <ve-col xs12 sm12 v-if="ud.persons[i].nationality&&ud.persons[i].nationality!='Svensk'">
                                    <ve-label >{{ __("otherCountryInfo") }}</ve-label>
                                    {{ud.persons[i].nationality}}
                                </ve-col>
                                <ve-col md12 xs12 v-if="ud.persons[i].nationality && ud.persons[i].nationality!='Svensk'">
                                  <ve-row v-if="passportFiles.length>0 && passportFiles[i]">
                                    <ve-col md2 xs6 class="atc-item" style="float:left;" >
                                        <transition name="slide-fade">
                                          <ve-pdf-viewer :src="passportFiles[i]"
                                          :showPages = [1]>
                                          </ve-pdf-viewer>
                                        </transition>
                                        <a class="remove-button-atc remove-file" @click="removeFile('passports',i)" style="font-weight:bold">
                                          <i class="fas fa-times"></i>
                                        </a>&nbsp;&nbsp;{{passportFiles[i].name}}
                                    </ve-col>
                                  </ve-row>
                                  <br>
                                  <label class="file-select">
                                    <div class="btn btn-primary next-btn">
                                      <span>{{ __("uploadButton") }}</span>
                                    </div>
                                    <input
                                      accept="application/pdf"
                                      class="btn btn-primary next-btn"
                                      type="file"
                                      @change="handleFileChange6($event,i,'persons')"
                                    />
                                  </label>
                                  <div class="file-error" v-if="!ud.persons[i].hasOwnProperty('noPassportFile') || ud.persons[i].noPassportFile">
                                  {{__('noFile')}}
                                  </div>
                                </ve-col>
                              </div>
                              <br>
                        </div>
                        <br>
                        <ve-col md2 xs12 v-if="ud.persons.length > 1" class="paddingtop15">
                          <button
                            class="btn btn-warning te"
                            type="button"
                            @click="ud.persons.pop()"
                          >
                            {{ __("removeOwner") }}
                          </button>
                        </ve-col>
                        <ve-col md3 v-if="ud.persons.length < 3" class="paddingtop15">
                          <button
                            class="btn btn-primary te"
                            type="button"
                            @click="addPersons()"
                          >
                            {{ __("addOwner") }}
                          </button>
                        </ve-col>
                        
                      </ve-row>
                      <br>
                      <ve-row>
                        <br>
                      <ve-col xs12 sm12>
                        <ve-form-group>
                          <ve-checkbox
                            v-model="ud.form4Info7"
                            inline
                            :options="{
                              yes: { label: __('form4Info7') }
                            }"
                          ></ve-checkbox>
                        </ve-form-group>
                      </ve-col>
                      
                      <ve-col md12 xs12 v-if="ud.form4Info7.yes">
                          <ve-row v-if="file4">
                            <ve-col md2 xs6 class="atc-item" style="float:left;" >
                                <transition name="slide-fade">
                                  <ve-pdf-viewer :src="file4"
                                  :showPages = [1]>
                                  </ve-pdf-viewer>
                                </transition>
                                <a class="remove-button-atc remove-file" @click="removeFile('file','5')" style="font-weight:bold">
                                  <i class="fas fa-times"></i>
                                </a>&nbsp;&nbsp;{{file4.name}}
                            </ve-col>
                          </ve-row>
                          <br>
                          <label class="file-select">
                            <div class="btn btn-primary next-btn">
                              <span>{{ __("uploadButton") }}</span>
                            </div>
                            <input
                              accept="application/pdf"
                              class="btn btn-primary next-btn"
                              type="file"
                              @change="handleFileChange4"
                            />
                          </label>
                          <div class="file-error" v-if="noFile2">
                            {{__('noFile')}}
                          </div>
                      </ve-col>
                    </ve-row>
                </div>







                <div v-if="ud.legalType&&(ud.legalType.legalFormOp5||ud.legalType.legalFormOp6)">
                   <p v-html="__('form6Info1') "></p><br>

                  <ve-row>
                      <ve-col md12 xs12>
                        <ve-custom-radio  v-model="ud.alternativeOption" nolabel
                          :options="{
                            alternative1: { label: __('form6Info2') }
                          }" :inline="true" rules="required"/>
                      </ve-col>
                      <p v-html="__('form6Info3') " class="col col-12 col-md-12 paddingleft25"></p>
                      <br>
                      <ve-row v-if="ud.alternativeOption.alternative1" class="paddingleft25">
                        <ve-col xs12 sm12>
                          <ve-form-group>
                            <ve-checkbox
                              v-model="ud.form6Info4"
                              inline
                              :options="{
                                yes: { label: __('form6Info4') }
                              }"
                              rules="required"
                            ></ve-checkbox>
                          </ve-form-group>
                        </ve-col>

                        <!-- <ve-col md12 xs12 v-if="isInQuestions('mainIncome')">
                          <ve-form-group>
                            <ve-label required class="tip-label" v-if="ud.isLegalForm&&ud.legalForm=='Enskild firma'">{{ __("mainIncome1") }}</ve-label>
                            <ve-label required class="tip-label" v-else>{{ __("mainIncome") }}</ve-label>
                            <ve-radio 
                              v-model="ud.mainIncome"
                              :options="{
                                incomeOp1: { label: __('incomeOp1') },
                                incomeOp2: { label: __('incomeOp2') },
                                incomeOp3: { label: __('incomeOp3') },
                                incomeOp4: { label: __('incomeOp4') }
                              }"
                              required
                              :disabled="isDisabledOp1()"
                            />
                          </ve-form-group>
                          <ve-input-field v-if="ud.mainIncome&&ud.mainIncome.incomeOp4"
                              md12
                              xs12
                              type="text"
                              v-model="ud.otherSource"
                              :label="__('otherSource')"
                              required
                              rules="required"
                              :disabled="isDisabledOp1()"
                            />
                        </ve-col>
                        <ve-col xs12 sm12>
                          <ve-form-group>
                            <ve-checkbox
                              v-model="ud.form6Info14"
                              inline
                              :options="{
                                yes: { label: __('form6Info14') }
                              }"
                              :disabled="isDisabledOp1()"
                            ></ve-checkbox>
                          </ve-form-group>
                        </ve-col> -->

                      </ve-row>
                  </ve-row>
                  <hr>
                  

                  <ve-row>
                      <ve-col md12 xs12>
                        <ve-custom-radio  v-model="ud.alternativeOption" nolabel
                          :options="{
                            alternative2: { label: __('form6Info5') }
                          }" :inline="true" rules="required"/>
                      </ve-col>
                      <p v-html="__('form6Info6') " class="col col-12 col-md-12 paddingleft25"></p>
                      <p v-html="__('form4Info8') " class="col col-12 col-md-12 paddingleft25" v-if="ud.alternativeOption.alternative2"></p>

                      <ve-row class="paddingleft25" v-if="ud.alternativeOption.alternative2">
                        <ve-col xs12 sm12>
                          <ve-form-group>
                            <ve-checkbox
                              v-model="ud.form6Info7"
                              inline
                              :options="{
                                yes: { label: __('form6Info7') }
                              }"
                            ></ve-checkbox>
                          </ve-form-group>
                        </ve-col>
                        <ve-col md12 xs12 v-if="ud.form6Info7.yes">
                            <ve-row v-if="file6">
                              <ve-col md2 xs6 class="atc-item" style="float:left;" >
                                  <transition name="slide-fade">
                                    <ve-pdf-viewer :src="file6"
                                    :showPages = [1]>
                                    </ve-pdf-viewer>
                                  </transition>
                                  <a class="remove-button-atc remove-file" @click="removeFile('file','7')" style="font-weight:bold">
                                    <i class="fas fa-times"></i>
                                  </a>&nbsp;&nbsp;{{file6.name}}
                              </ve-col>
                            </ve-row>
                            <br>
                            <label class="file-select">
                              <div class="btn btn-primary next-btn">
                                <span>{{ __("uploadButton") }}</span>
                              </div>
                              <input
                                accept="application/pdf"
                                class="btn btn-primary next-btn"
                                type="file"
                                @change="handleFileChange7"
                              />
                            </label>
                            <div class="file-error" v-if="noFile3">
                              {{__('noFile')}}
                            </div>
                        </ve-col>
                      </ve-row>

                      <p v-if="ud.alternativeOption.alternative2 && !ud.form6Info7.yes" v-html="__('form6Info8') " class="col col-12 col-md-12 paddingleft25" ></p>

                      <ve-row class="paddingleft25" v-if="ud.alternativeOption.alternative2 && !ud.form6Info7.yes">
                        <div v-for="(person, i) in ud.persons" :key="i" class="customPadding box">
                              <div class="row">
                                
                                <!-- <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("firstName") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons[i].firstName"
                                      :disabled="isDisabledOp2()"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>

                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("lastName") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons[i].lastName"
                                      :disabled="isDisabledOp2()"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col md6 xs12 sm12>
                                  <ve-form-group>
                                    <label required>{{ __('nationality') }}</label>
                                    <ve-dropdown
                                      v-model="ud.persons[i].nationality"
                                      rules="required"
                                      :items="ud.nationalityArray"
                                      :disabled="isDisabledOp2()"
                                    ></ve-dropdown>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm12 md6 v-if="ud.persons[i].nationality=='Annan'">
                                  <ve-form-group>
                                    <ve-label required>{{ __("otherNationality") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons[i].place"
                                      :disabled="isDisabledOp2()"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required v-if="ud.persons[i].nationality&&ud.persons[i].nationality!='Svensk'">{{ __("dob") }}</ve-label>
                                    <ve-label required v-else>{{ __("ssn") }}</ve-label>
                                    <datepicker v-model="ud.persons[i].ssn" v-if="ud.persons[i].nationality&&ud.persons[i].nationality!='Svensk'"
                                                  name="datepicker_dob"
                                                  id="datepicker-dob"
                                                  required
                                                  format="yyyy.MM.dd"
                                                  :language="sv"
                                                  :monday-first="true"
                                                   :input-class="'calendar-icon form-control'"
                                                   :disabled="isDisabledOp2()"
                                    ></datepicker>
                                    <ve-input v-else
                                      v-model="ud.persons[i].ssn"
                                      mask="########-####"
                                      placeholder="ÅÅÅÅMMDD-XXXX"
                                      :disabled="isDisabledOp2()"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("address") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons[i].address"
                                      :disabled="isDisabledOp2()"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("zip") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons[i].zip"
                                      :disabled="isDisabledOp2()"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("city") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons[i].city"
                                      :disabled="isDisabledOp2()"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col> -->

                                <ve-col md6 xs12 sm12>
                                  <ve-form-group>
                                    <label required>{{ __('nationality') }}</label>
                                    <ve-select
                                      :searchable="false"
                                      v-model="ud.persons[i].nationality"
                                      :options="ud.nationalityArray" 
                                       @on-select="ud.persons[i].nationality=='Svensk'?ud.persons[i].noPassportFile=false:ud.persons[i].noPassportFile=true"
                                      :readonly="true" rules="required"/>

                                  </ve-form-group>
                                </ve-col>

                                <ve-col xs12 sm12 md6 v-if="ud.persons[i].nationality=='Annan'">
                                    <ve-form-group>
                                      <ve-label required>{{ __("otherNationality") }}</ve-label>
                                      <ve-input v-model="ud.persons[i].place"  
                                      rules="required" blurvalidate ></ve-input>
                                    </ve-form-group>
                                </ve-col>

                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required v-if="ud.persons[i].nationality && ud.persons[i].nationality=='Svensk'">{{ __("ssn") }}</ve-label>
                                    <ve-label required v-if="ud.persons[i].nationality && ud.persons[i].nationality!='Svensk'">{{ __("dob") }}</ve-label>
                                    <ve-input v-if="ud.persons[i].nationality && ud.persons[i].nationality!='Svensk'"
                                      v-model="ud.persons[i].ssn"
                                      mask="####-##-##"
                                      placeholder="ÅÅÅÅ-MM-DD"  
                                      rules="required|dob"></ve-input>
                                    <ve-input v-if="ud.persons[i].nationality && ud.persons[i].nationality=='Svensk'"
                                      @input="fetchSignatoryBisnode(i)"
                                      v-model="ud.persons[i].ssn"
                                      rules="required|min:13"
                                      mask="########-####" 
                                      placeholder="ÅÅÅÅMMDD-XXXX"></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("firstName") }}</ve-label>
                                    <ve-input v-model="ud.persons[i].firstName" rules="required"></ve-input>
                                  </ve-form-group>
                                </ve-col>

                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("lastName") }}</ve-label>
                                    <ve-input v-model="ud.persons[i].lastName" rules="required"></ve-input>
                                  </ve-form-group>
                                </ve-col>

                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("address") }}</ve-label>
                                    <ve-input v-model="ud.persons[i].address" rules="required" ></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                 <ve-col xs12 sm6 v-if="ud.persons[i].nationality&&ud.persons[i].nationality!='Svensk'">
                                  <ve-form-group>
                                    <ve-label required>{{ __("zip") }}</ve-label>
                                    <ve-input v-model="ud.persons[i].zip" rules="required"></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6 v-if="ud.persons[i].nationality&&ud.persons[i].nationality=='Svensk'">
                                  <ve-form-group>
                                    <ve-label required>{{ __("zip") }}</ve-label>
                                    <ve-input v-model="ud.persons[i].zip" rules="required|zipse" mask="### ##"></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("city") }}</ve-label>
                                    <ve-input v-model="ud.persons[i].city" rules="required"></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                
                                <ve-col xs12 sm12 v-if="ud.persons[i].nationality&&ud.persons[i].nationality!='Svensk'">
                                    <ve-label >{{ __("otherCountryInfo") }}</ve-label>
                                </ve-col>

                                <ve-col md12 xs12 v-if="ud.persons[i].nationality && ud.persons[i].nationality!='Svensk'">
                                  <ve-row v-if="passportFiles.length>0 && passportFiles[i]">
                                    <ve-col md2 xs6 class="atc-item" style="float:left;" >
                                        <transition name="slide-fade">
                                          <ve-pdf-viewer :src="passportFiles[i]"
                                          :showPages = [1]>
                                          </ve-pdf-viewer>
                                        </transition>
                                        <a class="remove-button-atc remove-file" @click="removeFile('passports',i)" style="font-weight:bold">
                                          <i class="fas fa-times"></i>
                                        </a>&nbsp;&nbsp;{{passportFiles[i].name}}
                                    </ve-col>
                                  </ve-row>
                                  <br>
                                  <label class="file-select">
                                    <div class="btn btn-primary next-btn">
                                      <span>{{ __("uploadButton") }}</span>
                                    </div>
                                    <input
                                      accept="application/pdf"
                                      class="btn btn-primary next-btn"
                                      type="file"
                                      @change="handleFileChange6($event,i,'persons')"
                                    />
                                  </label>
                                  <div class="file-error" v-if="!ud.persons[i].hasOwnProperty('noPassportFile') || ud.persons[i].noPassportFile">
                                    {{__('noFile')}}
                                  </div>
                                </ve-col>
                              </div>
                              <br>
                        </div>
                        <br>
                        <ve-col md2 xs12 v-if="ud.persons.length > 1" class="paddingtop15">
                          <button
                            class="btn btn-warning te"
                            type="button"
                            @click="ud.persons.pop()"
                          >
                            {{ __("removeOwner") }}
                          </button>
                        </ve-col>
                        <ve-col md3 v-if="ud.persons.length < 3" class="paddingtop15">
                          <button
                            class="btn btn-primary te"
                            type="button"
                            @click="addPersons()"
                          >
                            {{ __("addOwner") }}
                          </button>
                        </ve-col>
                        <br>
                      </ve-row>

                      <br>
                      <ve-row v-if="ud.alternativeOption.alternative2" class="paddingleft25">
                        <br>
                        <ve-col xs12 sm12>
                          <ve-form-group>
                            <ve-checkbox
                              v-model="ud.form6Info9"
                              inline
                              :options="{
                                yes: { label: __('form6Info9') }
                              }"
                            ></ve-checkbox>
                          </ve-form-group>
                        </ve-col>
                        <ve-col md12 xs12 v-if="ud.form6Info9.yes">
                            <ve-row v-if="file5">
                              <ve-col md2 xs6 class="atc-item" style="float:left;" >
                                  <transition name="slide-fade">
                                    <ve-pdf-viewer :src="file5"
                                    :showPages = [1]>
                                    </ve-pdf-viewer>
                                  </transition>
                                  <a class="remove-button-atc remove-file" @click="removeFile('file','6')" style="font-weight:bold">
                                    <i class="fas fa-times"></i>
                                  </a>&nbsp;&nbsp;{{file5.name}}
                              </ve-col>
                            </ve-row>
                            <br>
                            <label class="file-select">
                              <div class="btn btn-primary next-btn">
                                <span>{{ __("uploadButton") }}</span>
                              </div>
                              <input
                                accept="application/pdf"
                                class="btn btn-primary next-btn"
                                type="file"
                                @change="handleFileChange5"
                              />
                            </label>
                            <div class="file-error" v-if="noFile2">
                              {{__('noFile')}}
                            </div>
                        </ve-col>
                    </ve-row>

                  </ve-row>
                  
                  <hr>

                  <ve-row>
                      <ve-col md12 xs12>
                        <ve-custom-radio  v-model="ud.alternativeOption" nolabel
                          :options="{
                            alternative3: { label: __('form6Info10') }
                          }" :inline="true" rules="required"/>
                      </ve-col>
                      <p v-html="__('form6Info11') " class="col col-12 col-md-12 paddingleft25"></p>
                      <p v-html="__('form4Info8') " class="col col-12 col-md-12 paddingleft25" v-if="ud.alternativeOption.alternative3"></p>

                      <ve-row class="paddingleft25" v-if="ud.alternativeOption.alternative3">
                        <ve-col xs12 sm12>
                          <ve-form-group>
                            <ve-checkbox
                              v-model="ud.form6Info12"
                              inline
                              :options="{
                                yes: { label: __('form6Info12') }
                              }"
                            ></ve-checkbox>
                          </ve-form-group>
                        </ve-col>



                        <ve-col md12 xs12 v-if="ud.form6Info12.yes">
                          <ve-row v-if="file6">
                            <ve-col md2 xs6 class="atc-item" style="float:left;" >
                                <transition name="slide-fade">
                                  <ve-pdf-viewer :src="file6"
                                  :showPages = [1]>
                                  </ve-pdf-viewer>
                                </transition>
                                <a class="remove-button-atc remove-file" @click="removeFile('file','7')" style="font-weight:bold">
                                  <i class="fas fa-times"></i>
                                </a>&nbsp;&nbsp;{{file6.name}}
                            </ve-col>
                          </ve-row>
                          <br>
                          <label class="file-select">
                            <div class="btn btn-primary next-btn">
                              <span>{{ __("uploadButton") }}</span>
                            </div>
                            <input
                              accept="application/pdf"
                              class="btn btn-primary next-btn"
                              type="file"
                              @change="handleFileChange7"
                            />
                          </label>
                          <div class="file-error" v-if="noFile3">
                            {{__('noFile')}}
                          </div>
                      </ve-col>
                      <br>
                      </ve-row>

                      <p v-html="__('form6Info13') " class="col col-12 col-md-12 paddingleft25" v-if="ud.alternativeOption.alternative3 && !ud.form6Info12.yes"></p>

                      <ve-row class="paddingleft25" v-if="ud.alternativeOption.alternative3 && !ud.form6Info12.yes">
                        <div v-for="(person, i) in ud.persons1" :key="i" class="customPadding box">
                              <div class="row">
                                
                                <!-- <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("firstName") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons1[i].firstName"
                                      :disabled="isDisabledOp3()"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>

                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("lastName") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons1[i].lastName"
                                      :disabled="isDisabledOp3()"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col md6 xs12 sm12>
                                  <ve-form-group>
                                    <label required>{{ __('nationality') }}</label>
                                    <ve-dropdown
                                      v-model="ud.persons1[i].nationality"
                                      rules="required"
                                      :items="ud.nationalityArray"
                                      :disabled="isDisabledOp3()"
                                    ></ve-dropdown>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm12 md6 v-if="ud.persons1[i].nationality=='Annan'">
                                  <ve-form-group>
                                    <ve-label required>{{ __("otherNationality") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons1[i].place"
                                      :disabled="isDisabledOp3()"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("ssn") }}</ve-label>
                                        <datepicker v-model="ud.persons1[i].ssn" v-if="ud.persons1[i].nationality&&ud.persons1[i].nationality!='Svensk'"
                                          name="datepicker_dob"
                                          id="datepicker-dob"
                                          required
                                          format="yyyy.MM.dd"
                                          :language="sv"
                                          :monday-first="true"
                                          :input-class="'calendar-icon form-control'"
                                          :disabled="isDisabledOp3()"
                                        ></datepicker>
                                    <ve-input v-else
                                      v-model="ud.persons1[i].ssn"
                                      mask="########-####"
                                      placeholder="ÅÅÅÅMMDD-XXXX"
                                      :disabled="isDisabledOp3()"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("address") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons1[i].address"
                                      :disabled="isDisabledOp3()"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("zip") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons1[i].zip"
                                      :disabled="isDisabledOp3()"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col>
                                <ve-col xs12 sm6>
                                  <ve-form-group>
                                    <ve-label required>{{ __("city") }}</ve-label>
                                    <ve-input
                                      v-model="ud.persons1[i].city"
                                      :disabled="isDisabledOp3()"
                                    ></ve-input>
                                  </ve-form-group>
                                </ve-col> -->

                        <ve-col md6 xs12 sm12>
                          <ve-form-group>
                            <label required>{{ __('nationality') }}</label>
                            <ve-select
                              :searchable="false"
                              v-model="ud.persons1[i].nationality"
                              :options="ud.nationalityArray" 
                              @on-select="ud.persons1[i].nationality=='Svensk'?ud.persons1[i].noPassportFile=false:ud.persons1[i].noPassportFile=true"
                              :readonly="true" rules="required"/>

                          </ve-form-group>
                        </ve-col>

                        <ve-col xs12 sm12 md6 v-if="ud.persons1[i].nationality=='Annan'">
                            <ve-form-group>
                              <ve-label required>{{ __("otherNationality") }}</ve-label>
                              <ve-input v-model="ud.persons1[i].place"  
                              rules="required" blurvalidate ></ve-input>
                            </ve-form-group>
                        </ve-col>

                        <ve-col xs12 sm6>
                          <ve-form-group>
                            <ve-label required v-if="ud.persons1[i].nationality && ud.persons1[i].nationality=='Svensk'">{{ __("ssn") }}</ve-label>
                            <ve-label required v-if="ud.persons1[i].nationality && ud.persons1[i].nationality!='Svensk'">{{ __("dob") }}</ve-label>
                            <ve-input v-if="ud.persons1[i].nationality && ud.persons1[i].nationality!='Svensk'"
                              v-model="ud.persons1[i].ssn"
                              mask="####-##-##"
                              placeholder="ÅÅÅÅ-MM-DD"  
                              rules="required|dob"></ve-input>
                            <ve-input v-if="ud.persons1[i].nationality && ud.persons1[i].nationality=='Svensk'"
                              @input="fetchSignatoryBisnode1(i)"
                              v-model="ud.persons1[i].ssn"
                              rules="required|min:13"
                              mask="########-####" 
                              placeholder="ÅÅÅÅMMDD-XXXX"></ve-input>
                          </ve-form-group>
                        </ve-col>
                        <ve-col xs12 sm6>
                          <ve-form-group>
                            <ve-label required>{{ __("firstName") }}</ve-label>
                            <ve-input v-model="ud.persons1[i].firstName" rules="required"></ve-input>
                          </ve-form-group>
                        </ve-col>

                        <ve-col xs12 sm6>
                          <ve-form-group>
                            <ve-label required>{{ __("lastName") }}</ve-label>
                            <ve-input v-model="ud.persons1[i].lastName" rules="required"></ve-input>
                          </ve-form-group>
                        </ve-col>

                        <ve-col xs12 sm6>
                          <ve-form-group>
                            <ve-label required>{{ __("address") }}</ve-label>
                            <ve-input v-model="ud.persons1[i].address" rules="required" ></ve-input>
                          </ve-form-group>
                        </ve-col>
                        <ve-col xs12 sm6 v-if="ud.persons1[i].nationality && ud.persons1[i].nationality!='Svensk'">
                          <ve-form-group>
                            <ve-label required>{{ __("zip") }}</ve-label>
                            <ve-input v-model="ud.persons1[i].zip" rules="required"></ve-input>
                          </ve-form-group>
                        </ve-col>

                        <ve-col xs12 sm6 v-if="ud.persons1[i].nationality&&ud.persons1[i].nationality=='Svensk'">
                          <ve-form-group>
                            <ve-label required>{{ __("zip") }}</ve-label>
                            <ve-input v-model="ud.persons1[i].zip" rules="required|zipse" mask="### ##"></ve-input>
                          </ve-form-group>
                        </ve-col>
                        <ve-col xs12 sm6>
                          <ve-form-group>
                            <ve-label required>{{ __("city") }}</ve-label>
                            <ve-input v-model="ud.persons1[i].city" rules="required"></ve-input>
                          </ve-form-group>
                        </ve-col>

                                <!-- <ve-col md12 xs12 v-if="isInQuestions('mainIncome')">
                                  <ve-form-group>
                                    <ve-label required class="tip-label" v-if="ud.isLegalForm&&ud.legalForm=='Enskild firma'">{{ __("mainIncome1") }}</ve-label>
                                    <ve-label required class="tip-label" v-else>{{ __("mainIncome") }}</ve-label>
                                    <ve-radio 
                                      v-model="ud.persons1[i].mainIncome"
                                      :options="{
                                        incomeOp1: { label: __('incomeOp1') },
                                        incomeOp2: { label: __('incomeOp2') },
                                        incomeOp3: { label: __('incomeOp3') },
                                        incomeOp4: { label: __('incomeOp4') }
                                      }"
                                      :rules="ud.persons1[i].firstName?'required':''"
                                      required
                                      :disabled="isDisabledOp3()"
                                    />
                                  </ve-form-group>
                                  <ve-input-field v-if="ud.persons1[i].mainIncome&&ud.persons1[i].mainIncome.incomeOp4"
                                      md12
                                      xs12
                                      type="text"
                                      v-model="ud.otherSource"
                                      :label="__('otherSource')"
                                      required
                                      rules="required"
                                      :disabled="isDisabledOp3()"
                                    />
                                </ve-col> -->
                          <ve-col xs12 sm12 v-if="ud.persons1[i].nationality && ud.persons1[i].nationality!='Svensk'">
                              <ve-label >{{ __("otherCountryInfo") }}</ve-label>
                          </ve-col>

                          <ve-col md12 xs12 v-if="ud.persons1[i].nationality && ud.persons1[i].nationality!='Svensk'">
                            <ve-row v-if="passportFiles.length>0 && passportFiles[i]">
                              <ve-col md2 xs6 class="atc-item" style="float:left;" >
                                  <transition name="slide-fade">
                                    <ve-pdf-viewer :src="passportFiles[i]"
                                    :showPages = [1]>
                                    </ve-pdf-viewer>
                                  </transition>
                                  <a class="remove-button-atc remove-file" @click="removeFile('passports',i)" style="font-weight:bold">
                                    <i class="fas fa-times"></i>
                                  </a>&nbsp;&nbsp;{{passportFiles[i].name}}
                              </ve-col>
                            </ve-row>
                            <br>
                            <label class="file-select">
                              <div class="btn btn-primary next-btn">
                                <span>{{ __("uploadButton") }}</span>
                              </div>
                              <input
                                accept="application/pdf"
                                class="btn btn-primary next-btn"
                                type="file"
                                @change="handleFileChange6($event,i,'persons1')"
                              />
                            </label>
                            <div class="file-error" v-if="!ud.persons[i].hasOwnProperty('noPassportFile') || ud.persons[i].noPassportFile">
                              {{__('noFile')}}
                            </div>
                          </ve-col>
                          </div>
                          <br>
                        </div>
                        <br>
                        <ve-col md2 xs12 v-if="ud.persons1.length > 1" class="paddingtop15">
                          <button
                            class="btn btn-warning te"
                            type="button"
                            @click="ud.persons1.pop()"
                          >
                            {{ __("removeOwner") }}
                          </button>
                        </ve-col>
                        <ve-col md3 v-if="ud.persons1.length < 3" class="paddingtop15">
                          <button
                            class="btn btn-primary te"
                            type="button"
                            @click="addPersons1()"
                          >
                            {{ __("addOwner") }}
                          </button>
                        </ve-col>
                        <br>
                      </ve-row>
                      <br>
                      <ve-row v-if="ud.alternativeOption.alternative3" class="paddingleft25">
                          <br>
                          <ve-col xs12 sm12>
                            <ve-form-group>
                              <ve-checkbox
                                v-model="ud.form6Info15"
                                inline
                                :options="{
                                  yes: { label: __('form6Info14') }
                                }"
                              ></ve-checkbox>
                            </ve-form-group>
                          </ve-col>
                          <ve-col md12 xs12 v-if="ud.form6Info15.yes">
                              <ve-row v-if="file5">
                                <ve-col md2 xs6 class="atc-item" style="float:left;" >
                                    <transition name="slide-fade">
                                      <ve-pdf-viewer :src="file5"
                                      :showPages = [1]>
                                      </ve-pdf-viewer>
                                    </transition>
                                    <a class="remove-button-atc remove-file" @click="removeFile('file','6')" style="font-weight:bold">
                                      <i class="fas fa-times"></i>
                                    </a>&nbsp;&nbsp;{{file5.name}}
                                </ve-col>
                              </ve-row>
                              <br>
                              <label class="file-select">
                                <div class="btn btn-primary next-btn">
                                  <span>{{ __("uploadButton") }}</span>
                                </div>
                                <input
                                  accept="application/pdf"
                                  class="btn btn-primary next-btn"
                                  type="file"
                                  @change="handleFileChange5"
                                />
                              </label>
                              <div class="file-error" v-if="noFile2">
                                {{__('noFile')}}
                              </div>
                          </ve-col>
                        </ve-row>
                  </ve-row>

                              
                </div>
              </ve-form>

              <ve-form v-if="step == 4 && !authorizationRequired && !completed" ref="form4" class="ml-3">
                <br>
                <h4>{{ __("sectionHead7") }}</h4>           
                <ve-row>
                  <ve-col xs12 sm12>
                    <ve-form-group>
                      <ve-custom-checkbox
                        v-model="ud.agree"
                        rules="required"
                        inline
                        :options="{
                          agree: { label: __('agree') }
                        }"
                        :data="{ validation_error: 'Test' }"
                      ></ve-custom-checkbox>
                    </ve-form-group>
                  </ve-col>
                  <ve-col xs12 sm12>
                    <ve-form-group>
                      <ve-custom-checkbox
                        v-model="ud.agree1"
                        rules="required"
                        inline
                        :options="{
                          agree1: { label: __('agree1') }
                        }"
                        :data="{ validation_error: 'Test' }"
                      ></ve-custom-checkbox>
                    </ve-form-group>
                  </ve-col>
                </ve-row>
                <h4>{{ __("signDocument") }}</h4>
                <p v-html="__('signInfo') "></p>
                <br>
                <h4>{{ __("firmaTeckning1") }}</h4>
                <p v-for="val in signature">{{val}}</p><br>
                <p v-html="__('firmaTeckning2') "></p><br>
                <p v-html="__('firmaTeckning3') " v-if="ud.legalForm != 'Enskild firma'"></p>
                <br>
                <ve-row>
                  <h4>{{ __("sectionHead8") }}</h4>
                  <ve-col md12 xs12>
                    <ve-form-group>
                      <ve-radio
                        v-model="ud.forward"
                        :label="__('forward')"
                        :options="{
                          signDirect: { label: __('signDirect') },
                          signForward: { label: __('signForward') }
                        }"
                        :inline="false"
                        @radio-click="updateBoardMembers"
                        rules="required"
                      />
                    </ve-form-group>
                  </ve-col>
                </ve-row>
                <ve-row v-if="(ud.forward.signForward || ud.forward.signDirect) && !isEmpty()">
                  <ve-label :label="__('chooseNameFromList')" />
                  <ve-col md12 xs12>
                    <ve-select
                      :options="ud.boardMembersList"
                      :searchable="true"
                      v-model="ud.boardMembers"
                      rules="required"
                      required
                      @on-select="updateOtherBoardMembers"
                    />
                  </ve-col>
                  <ve-col md6 xs12 v-if="ud.boardMembers && ud.boardMembers !='Jag är inte med i listan men har rätt att signera (fullmakt)'">
                    <ve-input-field
                      :label="__('my_email')"
                      type="email"
                      v-model="ud.my_email"
                      rules="required|email"
                      required
                    />
                  </ve-col>
                  <ve-col md6 xs12 v-if="
                      ud.boardMembers &&
                        ud.boardMembers !=
                          'Jag är inte med i listan men har rätt att signera (fullmakt)'
                    ">
                        <ve-form-group>
                          <ve-label :label="__('signingMethod')" required />
                          <ve-select
                            :options="methods"
                            v-model="ud.signingMethod"
                            rules="required"
                          />
                        </ve-form-group>
                      </ve-col>
                  <ve-col
                    md6
                    xs12
                    v-if="
                      ud.boardMembers ==
                        'Jag är inte med i listan men har rätt att signera (fullmakt)'
                    "
                  >
                    <ve-input-field
                      :label="__('nolist_firstName')"
                      type="text"
                      v-model="ud.nolist_firstName"
                      required
                      rules="required"
                    />
                  </ve-col>
                  <ve-col
                    md6
                    xs12
                    v-if="
                      ud.boardMembers ==
                        'Jag är inte med i listan men har rätt att signera (fullmakt)'
                    "
                  >
                    <ve-input-field
                      :label="__('nolist_lastName')"
                      type="text"
                      v-model="ud.nolist_lastName"
                      required
                      rules="required"
                    />
                  </ve-col>
                  <ve-col
                    md6
                    xs12
                    v-if="
                      ud.boardMembers ==
                        'Jag är inte med i listan men har rätt att signera (fullmakt)'
                    "
                  >
                    <ve-input-field
                      :label="__('nolist_email')"
                      type="email"
                      v-model="ud.nolist_email"
                      required
                      rules="required|email"
                    />
                  </ve-col>
                  <ve-col md6 xs12 v-if="
                      ud.boardMembers ==
                        'Jag är inte med i listan men har rätt att signera (fullmakt)'
                    ">
                        <ve-form-group>
                          <ve-label :label="__('signingMethod')" required />
                          <ve-select
                            :options="methods"
                            v-model="ud.signingMethod"
                            rules="required"
                          />
                        </ve-form-group>
                      </ve-col>
                </ve-row>

                <ve-row v-if="(ud.forward.signForward || ud.forward.signDirect) && isEmpty()">
                <ve-col md12 xs12>
                  <ve-label
                    xs12
                    :label="__('fillSignEmail')"
                    v-if="ud.forward.signDirect"
                  />
                  <ve-label
                    xs12
                    :label="__('fillSignForwardEmail')"
                    v-if="ud.forward.signForward"
                  />
                </ve-col>
                  <ve-col md6 xs12>
                    <ve-input-field
                      :label="__('nolist_firstName')"
                      type="text"
                      v-model="ud.nolist_firstName"
                      required
                      rules="required"
                    />
                  </ve-col>
                  <ve-col md6 xs12>
                    <ve-input-field
                      md12
                      xs12
                      :label="__('nolist_lastName')"
                      type="text"
                      v-model="ud.nolist_lastName"
                      required
                      rules="required"
                    />
                  </ve-col>
                  <ve-col md6 xs12>
                    <ve-input-field
                      md12
                      xs12
                      :label="__('nolist_email')"
                      type="email"
                      v-model="ud.nolist_email"
                      required
                      rules="required|email"
                    />
                  </ve-col>
                  <ve-col md6 xs12 >
                        <ve-form-group>
                          <ve-label :label="__('signingMethod')" required />
                          <ve-select
                            :options="methods"
                            v-model="ud.signingMethod"
                            rules="required"
                          />
                        </ve-form-group>
                      </ve-col>
                </ve-row>

                <ve-row v-if="(ud.forward.signForward || ud.forward.signDirect) && isEmpty() && ud.legalForm != 'Enskild firma'">
                  <ve-col xs12 sm12>
                    <ve-form-group>
                      <ve-checkbox
                        v-model="ud.noListAddSigner"
                        inline
                        :options="{
                          sign1: { label: __('chooseSecondNameFromList') }
                        }"
                      ></ve-checkbox>
                    </ve-form-group>
                  </ve-col>
                </ve-row>

                <ve-row v-if="ud.noListAddSigner && ud.noListAddSigner.sign1 == true">
                  <ve-col md6 xs12>
                    <ve-input-field
                      :label="__('secondlist_firstName')"
                      type="text"
                      v-model="ud.secondlist_firstName"
                      required
                      rules="required"
                    />
                  </ve-col>
                  <ve-col md6 xs12>
                    <ve-input-field
                      md12
                      xs12
                      :label="__('secondlist_lastName')"
                      type="text"
                      v-model="ud.secondlist_lastName"
                      required
                      rules="required"
                    />
                  </ve-col>
                  <ve-col md6 xs12>
                    <ve-input-field
                      md12
                      xs12
                      :label="__('secondlist_email')"
                      type="email"
                      v-model="ud.secondlist_email"
                      required
                      rules="required|email"
                    />
                  </ve-col>
                  <ve-col md6 xs12 >
                        <ve-form-group>
                          <ve-label :label="__('signingMethod')" required />
                          <ve-select
                            :options="methods"
                            v-model="ud.signingMethod2"
                            rules="required"
                          />
                        </ve-form-group>
                      </ve-col>
                </ve-row>

                <ve-row v-if="(ud.forward.signForward || ud.forward.signDirect) && ud.boardMembers && ud.legalForm != 'Enskild firma'">
                  <ve-col xs12 sm12>
                    <ve-form-group>
                      <ve-checkbox
                        v-model="ud.org_signing"
                        inline
                        :options="{
                          sign: { label: __('org_signing') }
                        }"
                      ></ve-checkbox>
                    </ve-form-group>
                  </ve-col>
                </ve-row>
                <ve-row v-if="ud.org_signing && ud.org_signing.sign">
                  <ve-label md12 xs12 :label="__('choosefromBoardMemberList')" required/>
                  <ve-col md12 xs12>
                    <ve-select
                      :options="ud.otherBoardMembersList"
                      :searchable="true"
                      v-model="ud.otherBoardMembers"
                      rules="required"
                    />
                  </ve-col>
                  <ve-col md6 xs12 v-if="ud.otherBoardMembers">
                    <ve-input-field
                      :label="__('other_email')"
                      type="email"
                      v-model="ud.other_email"
                      required
                      rules="required|email"
                    />
                  </ve-col>
                   <ve-col md6 xs12 v-if="ud.otherBoardMembers">
                        <ve-form-group>
                          <ve-label :label="__('signingMethod')" required />
                          <ve-select
                            :options="methods"
                            v-model="ud.signingMethod2"
                            rules="required"
                          />
                        </ve-form-group>
                      </ve-col>
                </ve-row>
              </ve-form>

              <ve-form v-if="authorizationRequired && !completed" ref="form5" class="ml-3">
               <ve-row>
                  <ve-col md12 xs12 >
                      {{ __("suppenaQA") }} <br />
                  </ve-col>
               </ve-row>
               
               
                <ve-row>
                  <ve-col md12 xs12 >
                      <ve-row v-if="ud.authorizationFiles.length>0">
                        <ve-col md2 xs6 class="atc-item" style="float:left;" v-for="(file, i) in ud.authorizationFiles" :key="i">
                            <transition name="slide-fade">
                              <ve-pdf-viewer :src="file"
                              :showPages = [1]>
                              </ve-pdf-viewer>
                            </transition>
                            <a class="remove-button-atc remove-file" @click="removeFile('authorization',k)" style="font-weight:bold">
                              <i class="fas fa-times"></i>
                            </a>&nbsp;&nbsp;{{file.name}}
                        </ve-col>
                      </ve-row>
                      <br>
                      <label class="file-select">
                        <div class="btn btn-primary next-btn">
                          <span>{{ __("uploadButton") }}</span>
                        </div>
                        <input
                          accept="application/pdf"
                          class="btn btn-primary next-btn"
                          type="file"
                          @change="handleauthorizationFileChange"
                        />
                      </label>
                  </ve-col>
                  <br>
                  <div class="file-error" style="padding:7px;" v-if="ud.authorizationFiles.length<1">
                    {{__('noFile')}}
                  </div>

                  <!-- <ve-col md12 xs12>
                   <br />
                    <label class="file-select">
                      <div class="btn btn-primary next-btn">
                        <span>{{ __("uploadButton") }}</span>
                      </div>
                      <input
                        accept="application/pdf"
                        class="btn btn-primary next-btn"
                        type="file"
                        @change="handleauthorizationFileChange"
                      />
                    </label>
                    <br />
                    <ul v-if="ud.authorizationFiles.length>0">
                      <li v-for="file in ud.authorizationFiles" :key="k">
                        {{
                          file.name
                        }}&nbsp;&nbsp;&nbsp;&nbsp;<a
                          @click="removeFile('authorization',file)"
                          >X</a
                        >
                      </li>
                    </ul>

                  </ve-col> -->
                </ve-row>
                <br />
              </ve-form>
               <div>
                  <br>
                  <ve-row v-if="completed" style="padding: 0 30px;" >
                    <ve-col md12 xs12 class="alert alert-success padding5rem" >
                      {{__('thankYou')}}
                    </ve-col>
                  </ve-row>
                  <ve-row>
                    <!--<button @click="previousStep()" type="button" class="btn btn-primary next-btn" v-if="step==2">
                        <span >{{ __('back') }}</span>
                    </button>-->
                    <ve-col md8 xs12 class="noPadding">
                    </ve-col>
                    
                    <ve-col md4 xs12 class="noPadding" v-if="!completed">
                      <button class="btn btn-primary next-btn" :disabled="!isBisnodeFetched" style="width:50%;float: right;" type="button" @click="nextStep()">{{__('stepper.continue')}}</button>
                    </ve-col>
                  </ve-row>
                  <br>
                </div>
            </div>
          </div>
          <!-- #endregion -->
        </ve-col>
      </ve-row>
    </div>
  </div>
</template>

<script>
export { default } from "./script.js";
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.inline-input {
  display: inline;
}

.inline-input > input {
  display: inline;
  width: 100px;
}
#cover {
  width: 100%;
  overflow: scroll;
  height: 100%;
  position: absolute;
  bottom: 0;
  background-color: white;
}
.multiselect__tag{
  background: rgba(0,17,23,.5);
}
.multiselect__option--highlight {
  background: rgba(0,17,23,.5);
}
</style>
